import { ApolloCache } from '@apollo/client';

export default (cache: ApolloCache<any>, count = 1) => {
  cache.modify({
    fields: {
      publishedMediaItems(existingValue) {
        return {
          ...existingValue,
          totalCount: existingValue.totalCount - count,
        };
      },
    },
  });
};
