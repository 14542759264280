import React from 'react';
import cx from 'classnames';
import { Avatar } from 'components';

const Option = (props: any) => {
  // clear out the onMouseMove and onMouseOver events for improved perf
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;

  return (
    <div
      className={cx(
        'flex items-center px-0.75 py-1 text-dark hover:bg-greyBg text-sm',
        { 'bg-greyBg': props.isFocused }
      )}
      {...newInnerProps}
      ref={props.innerRef}
    >
      {props.data.hasOwnProperty('image') && (
        <div className="flex-shrink-0 inline-block mr-0.5 w-3 h-3">
          <Avatar src={props.data.image} name={props.data.label} />
        </div>
      )}
      {props.data.label && props.data.label.replace && (
        <span
          className="leading-tight bump-up-0.5 flex-1 break-words text-left overflow-hidden"
          dangerouslySetInnerHTML={{
            __html: props.data.label.replace(
              props.selectProps.inputValue,
              `<strong>${props.selectProps.inputValue}</strong>`
            ),
          }}
        />
      )}
    </div>
  );
};

export default Option;
