import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ExtendedLinkProps extends LinkProps {
  to: string;
  children: React.ReactNode;
}

export default function ExtendedLink(props: ExtendedLinkProps) {
  const { to, children, ...restProps } = props;

  return (
    <Link
      to={{
        pathname: to,
        search: window.location.search,
      }}
      {...restProps}
    >
      {children}
    </Link>
  );
}
