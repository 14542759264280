import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  error: any;
}

const ErrorDebug = ({ error }: Props) => {
  if (process.env.NODE_ENV !== 'development') {
    window['Raven'] && window['Raven'].captureException(error);
  }

  return (
    <div data-testid="error-debug">
      <FormattedMessage id="Global__GraphQLError" />
      <br />
      Here's the error:
      <br />
      <pre className="bg-grey1 p-1 text-white w-full max-h-48 overflow-y-auto text-sm">
        {JSON.stringify(error, null, ' ')}
      </pre>
    </div>
  );
};

export default ErrorDebug;
