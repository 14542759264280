import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { InjectIntlContext } from 'hooks/useIntl';
import { FlashMessagesProvider } from 'hooks/useFlashMessages';
import Layout from 'Layout';
import apolloClient from 'graphql/apolloClient';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import messages from 'locales/en.json';

addLocaleData(en);

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <IntlProvider locale="en" messages={messages}>
        <InjectIntlContext>
          <FlashMessagesProvider>
            <Layout />
          </FlashMessagesProvider>
        </InjectIntlContext>
      </IntlProvider>
    </ApolloProvider>
  );
}
