import * as PUBLISHED_MEDIA_ITEMS_BY_ID from 'graphql/queries/PublishedMediaItemsById.graphql';
import apolloClient from '../apolloClient';

export default async (ids: number[]) => {
  const { errors, data } = await apolloClient.query({
    query: PUBLISHED_MEDIA_ITEMS_BY_ID,
    variables: { ids, first: ids.length },
    fetchPolicy: 'network-only',
  });

  if (errors) return { errors };
  if (data) return data;
  return null;
};
