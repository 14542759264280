import React from 'react';
import cx from 'classnames';
import { IconCheck } from 'icons';

interface Props extends React.ComponentProps<'button'> {
  checked?: boolean;
  onChange: () => void;
}

export default function Checkbox(props: Props) {
  const { checked, onChange, ...butttonProps } = props;

  return (
    <button
      {...butttonProps}
      onClick={onChange}
      className={cx(
        'block bg-white border-2 rounded-sm border-solid w-2.5 h-2.5 hover:border-socialiePinkHover',
        {
          'bg-socialiePink border-socialiePink hover:bg-socialiePinkHover': checked,
          'border-grey7': !checked,
        }
      )}
    >
      <span className="flex justify-center items-center">
        {checked && (
          <IconCheck className="block fill-current w-full h-full text-white pointer-events-none" />
        )}
      </span>
    </button>
  );
}
