import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

interface Props {
  value: number | Date;
  separator?: React.ReactNode;
  month?: string;
  day?: string;
  year?: string | false;
}

const FormattedDateTime = ({
  value,
  separator = <br />,
  year,
  month = 'long',
  day = '2-digit',
}: Props) => {
  // By default, show the year only if it's not the current year
  const isCurrentYear =
    new Date(value).getFullYear() === new Date().getFullYear();
  const defaultYear = isCurrentYear ? undefined : 'numeric';

  // If the user passed in a year prop, use that.  If they passed in {false}
  // then change it to undefined.
  let yearValue = typeof year === 'undefined' ? defaultYear : year;
  if (yearValue === false) yearValue = undefined;

  return (
    <>
      <FormattedDate value={value} month={month} day={day} year={yearValue} />
      {separator}
      <FormattedTime value={value} />
    </>
  );
};

export default FormattedDateTime;
