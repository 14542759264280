import React, { InputHTMLAttributes } from 'react';
import { IconCaratDown } from 'icons';

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  options: {
    value: string;
    label: string;
  }[];
}

export default function Select({ options, ...selectProps }: Props) {
  return (
    <div className="inline-block relative w-full">
      <select
        className="w-full h-5 p-1 py-1 pr-4 bg-white border font-sans border-grey7 rounded leading-tight appearance-none focus:outline-none"
        {...selectProps}
      >
        {options.map(o => (
          <option key={o.value} value={o.value} defaultValue={options[0].value}>
            {o.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-dark">
        <IconCaratDown className="h-2 w-2" />
      </div>
    </div>
  );
}
