import React from 'react';
import ReactDOM from 'react-dom';
import 'socket';
import App from './App';

// CSS
import 'style/index.css';
import 'react-datepicker/dist/react-datepicker.css';

const rootEl = document.getElementById('root') as HTMLElement;

declare global {
  interface Window {
    Socialie: { tempUserToken?: string };
  }
}

window.Socialie = window.Socialie || {};

ReactDOM.render(<App />, rootEl);
