import React from 'react';
import Face from 'types/Face';

interface Props {
  src: string;
  aspectRatio: number;
  faces?: Omit<Face, 'confidence'>[];
  showFaceCount?: boolean;
}

export default function ImageWithFaces(props: Props) {
  const { src, aspectRatio, faces, showFaceCount } = props;
  const classNames = aspectRatio > 1 ? 'h-full' : 'h-0 w-full';
  const styles =
    aspectRatio > 1
      ? { width: `${(1 / aspectRatio) * 100}%` }
      : { paddingBottom: `${aspectRatio * 100}%` };

  return (
    <div
      className={`relative ${classNames}`}
      style={{
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        ...styles,
      }}
    >
      {faces && (
        <>
          {showFaceCount && <FaceCount faceCount={faces.length} />}

          {faces.map(face => (
            <div
              key={face.id}
              className="absolute z-10 border shadow"
              style={{
                top: `${face.top * 100}%`,
                left: `${face.left * 100}%`,
                width: `${face.width * 100}%`,
                height: `${face.height * 100}%`,
                transform: 'scale(1.2)',
                transformOrigin: '50% 50%',
              }}
            />
          ))}
        </>
      )}
    </div>
  );
}

function FaceCount({ faceCount }: { faceCount: number }) {
  return (
    <div className="absolute top-0 left-0 z-10 ml-0.5 mt-0.5 rounded bg-white p-0.5 text-xxs text-light">
      <span role="img" aria-label="Face Count">
        😃
      </span>{' '}
      {faceCount}
    </div>
  );
}
