import React from 'react';
import { FormattedMessage } from 'react-intl';
import includes from 'lodash/includes';
import FlyoutMenu from './FlyoutMenu';
import TimeSeriesValue from 'types/TimeSeriesValue';
import { IconTriangleDown } from 'icons';

interface Props {
  timeSeries: TimeSeriesValue;
  dateRange: string;
  onChange: (value: TimeSeriesValue) => void;
}

const disabledCombos = {
  day: [],
  week: ['last7'],
  month: ['last7', 'last30'],
};

const TimeSeriesSelector = ({ timeSeries, dateRange, onChange }: Props) => {
  const options: TimeSeriesValue[] = ['day', 'week', 'month'];

  return (
    <FlyoutMenu
      placement="bottom-end"
      renderButton={onClick => (
        <div className="flex items-center">
          <div className="relative">
            <button type="button" onClick={onClick}>
              <span className="flex items-center text-sm">
                <FormattedMessage id={`TimeSeries--${timeSeries}`} />
                <IconTriangleDown className="block ml-1 w-1.5 h-1.5 text-dark" />
              </span>
            </button>
          </div>
        </div>
      )}
    >
      {options.map(tsOption => (
        <button
          disabled={includes(disabledCombos[tsOption], dateRange)}
          key={tsOption}
          onClick={() => onChange(tsOption)}
        >
          <FormattedMessage id={`TimeSeries--${tsOption}`} />
        </button>
      ))}
    </FlyoutMenu>
  );
};

export default TimeSeriesSelector;
