// Returns true if the passed element or any of its parents have
// the specified className
export default (element: any, className: string) => {
  let currentElement: any = element;
  do {
    if (
      currentElement &&
      currentElement.classList &&
      currentElement.classList.contains(className)
    ) {
      return true;
    }
    currentElement = currentElement && currentElement.parentNode;
  } while (currentElement);
  return false;
};
