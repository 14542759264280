const primitives = {
  transparent: 'transparent',
  grey1: '#101820',
  grey2: '#1c252f',
  grey3: '#343d46',
  grey4: '#5a636e',
  grey5: '#9198a1',
  grey6: '#c9ced4',
  grey7: '#dfe2e6',
  grey8: '#f0f2f5',

  white: 'white',
  black: '#000',
  teal: '#46b4b4',
  darkTeal: '#46b4b4',
  tealBg: '#eff8f8',
  yellow: '#ff9f40',
  yellowBg: '#fff5eb',
  orange: '#e85917',
  blue: '#569fe5',
  blueBg: '#ecf6fd',
  red: '#ed6c85',
  redBg: '#fdeff2',
  green: '#4bc0c0',

  error: '#ff2b2b',
  errorBg: '#ffeded',

  facebook: '#3b549b',
  instagram: '#e73558',
  linkedin: '#1b73b7',
  twitter: '#52a8f0',
  socialiePink: '#FC2361',
  socialiePinkHover: '#FF3D71',
  socialieBlue: '#5CC7EA',
};

const named = {
  bodyText: primitives.grey4,
  border: primitives.grey7,
  boxShadow: 'rgba(0, 0, 0, 0.12)',
  darkBorder: primitives.grey6,
  darkerBorder: primitives.grey4,
  darkestGreyBg: primitives.grey4,
  darkGreyBg: primitives.grey6,
  dark: primitives.grey1,
  darkText: primitives.grey1,
  draftBuilderBg: primitives.grey2,
  focus: primitives.grey6,
  grey: primitives.grey4,
  blue: primitives.blue,
  greyBg: primitives.grey8,
  inverseBorder: primitives.grey3,
  messageBg: '#36a2eb',
  lightBorder: primitives.grey8,
  lighterText: primitives.grey6,
  lightIcon: primitives.grey6,
  lightText: primitives.grey5,
  link: primitives.blue,
};

const colors = { ...primitives, ...named };

export default colors;
