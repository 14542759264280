import React, { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from 'appHistory';
import User from 'types/User';
import * as CURRENT_USER_QUERY from 'graphql/queries/CurrentUser.graphql';
import { routes } from 'helpers';
import { ErrorDebug, Loader } from 'components';
import { FlashMessages, TopBar } from './components';
import { CurrentUserProvider } from 'hooks/useCurrentUser';
import { lazy } from 'react';

const Auth = lazy(() => import('screens/Auth'));
const Customers = lazy(() => import('screens/CustomerIndex'));
const Customer = lazy(() => import('screens/CustomerShow'));
const Suggester = lazy(() => import('screens/SuggesterShow'));
const MagicTracking = lazy(() => import('screens/MagicTracking'));
const Reports = lazy(() => import('screens/Reports'));
const FacePrints = lazy(() => import('screens/FacePrints'));
const FanAccounts = lazy(() => import('screens/FanAccounts'));
const FanAccount = lazy(() => import('screens/FanAccount'));
const Comments = lazy(() => import('screens/Comments'));
const NewComment = lazy(() => import('screens/NewComment'));
interface CurrentUserQueryData {
  currentUser?: User;
  semiLoggedInUser?: User;
}

export default function Layout() {
  const { data, loading, error } = useQuery<CurrentUserQueryData>(
    CURRENT_USER_QUERY
  );
  const currentUser = data?.currentUser;
  const semiLoggedInUser = data?.semiLoggedInUser;

  if (
    semiLoggedInUser &&
    semiLoggedInUser.enableMfa &&
    window.localStorage.getItem('satellizer_token')
  ) {
    window.localStorage.setItem('satellizer_token', '');
    window.location.href = '/';
    return null;
  }

  return (
    <Router history={history}>
      <>
        <FlashMessages />

        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorDebug error={error} />
        ) : !currentUser ||
          (!currentUser.coolKid && !currentUser.coolKidRoles?.length) ? (
          <Suspense fallback={<Loader />}>
            <Auth semiLoggedInUser={semiLoggedInUser} />
          </Suspense>
        ) : (
          <CurrentUserProvider value={currentUser}>
            <div className="pt-8 text-bodyText bg-greyBg min-h-screen">
              <TopBar />

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route
                    path={routes.magicTracking.index}
                    component={MagicTracking}
                  />

                  <Route
                    exact
                    path={routes.customers.index}
                    component={Customers}
                  />

                  <Route
                    exact
                    path={routes.customers.show}
                    component={Customer}
                  />

                  <Route
                    path={routes.customers.suggesterShow}
                    component={Suggester}
                  />

                  <Route path={routes.reports.index} component={Reports} />

                  <Route
                    path={routes.facePrints.index}
                    component={FacePrints}
                  />

                  <Route
                    path={routes.fanAccounts.show}
                    component={FanAccount}
                  />

                  <Route
                    path={routes.fanAccounts.index}
                    component={FanAccounts}
                  />

                  <Route path={routes.comments.new} component={NewComment} />

                  <Route path={routes.comments.index} component={Comments} />

                  <Redirect to={routes.magicTracking.posts.index} />
                </Switch>
              </Suspense>
            </div>
          </CurrentUserProvider>
        )}
      </>
    </Router>
  );
}
