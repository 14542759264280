export default {
  auth: {
    login: '/log-in',
    mfaLogin: '/log-in-2fa',
  },

  comments: {
    index: '/comments',
    new: '/comments/new',
  },

  customers: {
    index: '/customers',
    show: '/customers/:customerId',
    suggesterShow: '/customers/:customerId/suggesters/:suggesterId',
  },

  fanAccounts: {
    index: '/fan-accounts',
    show: '/fan-accounts/:id',
  },

  magicTracking: {
    index: '/magic-tracking',
    reviewed: '/magic-tracking/reviewed',
    showReviewed: '/magic-tracking/reviewed/:id(\\d+)',
    posts: {
      index: '/magic-tracking/posts',
      show: '/magic-tracking/posts/:id(\\d+)',
      compare: '/magic-tracking/posts/:id/compare/:requestId',
    },
    stories: {
      index: '/magic-tracking/stories',
      show: '/magic-tracking/stories/:id(\\d+)',
      compare: '/magic-tracking/stories/:id/compare/:requestId',
    },
    reporting: '/magic-tracking/reporting',
  },

  reports: {
    index: '/reports',
    suggestions: '/reports/suggestions',
    requests: '/reports/requests',
    users: '/reports/users',
    posts: '/reports/posts',
    impact: '/reports/impact',
    stats: {
      suggestions: '/reports/stats/suggestions',
      users: '/reports/stats/users',
      followers: '/reports/stats/followers',
      posts: '/reports/stats/posts',
      ios: '/reports/stats/ios',
    },
  },

  facePrints: {
    index: '/face-prints',
  },
};
