import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { useIntl } from 'hooks';
import { Transition } from 'react-transition-group';

const OFFSET = 100;

export default function BackToTopButton() {
  const { t } = useIntl();
  const [isVisible, setIsVisible] = useState(window.scrollY > OFFSET);

  const [handleScrollChanged] = useDebouncedCallback(
    () => {
      if (window.scrollY > OFFSET && !isVisible) setIsVisible(true);
      if (window.scrollY <= OFFSET && isVisible) setIsVisible(false);
    },
    200,
    { maxWait: 500 }
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChanged);
    return () => {
      window.removeEventListener('scroll', handleScrollChanged);
    };
  });

  return (
    <Transition timeout={150} in={isVisible} unmountOnExit={true}>
      {state => (
        <div
          className={cx(
            'fixed bottom-0 right-0 pb-3 pr-3 transition-opacity duration-150 z-10',
            {
              'opacity-0': state === 'entering' || state === 'exiting',
              'opacity-100': state === 'entered',
            }
          )}
        >
          <button
            className="rounded-full bg-white text-dark p-1 px-2 shadow"
            onClick={() => window.scrollTo(0, 0)}
          >
            {t('BackToTopButton__Button')}
          </button>
        </div>
      )}
    </Transition>
  );
}
