import React from 'react';
import logoUrl from 'images/logo-socialie.png';

interface Props {
  children: React.ReactNode;
  bottomLink?: React.ReactNode;
  showLogo?: boolean;
}

const AuthForm = ({ children, bottomLink, showLogo = true }: Props) => (
  <div className="pt-2">
    <div
      className="mx-auto rounded shadow bg-white w-48"
      data-testid="auth-form"
    >
      <div className="p-2">
        {showLogo && (
          <img
            className="block mx-auto mb-4 w-24"
            data-testid="socialie-logo"
            src={logoUrl}
            alt="Socialie"
          />
        )}
        {children}
      </div>

      {bottomLink && (
        <div className="border-t p-0.5 center" data-testid="bottom-bar">
          {bottomLink}
        </div>
      )}
    </div>
  </div>
);

export default AuthForm;
