import React from 'react';
import FlyoutMenu from './FlyoutMenu';
import { FormattedMessage } from 'react-intl';
import includes from 'lodash/includes';
import { IconTriangles } from 'icons';

interface Props {
  value?: string;
  name: string;
  options: string[];
  onChange: (val: string) => void;
  right?: boolean;
}

interface State {
  isOpen: boolean;
}

export default class Selector extends React.Component<Props, State> {
  render() {
    const { options, name } = this.props;
    let value = this.props.value;
    if (!includes(options, value)) {
      value = options[0];
    }

    return (
      <FlyoutMenu
        renderButton={onClick => (
          <div className="relative text-sm">
            <button onClick={onClick}>
              <span className="flex items-center text-dark">
                <span className="mr-0.5">
                  <FormattedMessage id={`${name}--${value}`} />
                </span>
                <IconTriangles className="w-2 h-2" />
              </span>
            </button>
          </div>
        )}
      >
        {options.map(option => (
          <div key={option} onClick={() => this.props.onChange(option)}>
            <FormattedMessage id={`${name}--${option}`} />
          </div>
        ))}
      </FlyoutMenu>
    );
  }
}
