import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  crumbs: {
    title: string;
    route: string;
  }[];
}

export default function Breadcrumb({ crumbs }: BreadcrumbProps) {
  return (
    <div className="flex items-center text-sm">
      {crumbs.map((crumb, idx) => {
        return idx === crumbs.length - 1 ? (
          <div key={crumb.route}>
            <p className="text-white">{crumb.title}</p>
          </div>
        ) : (
          <div key={crumb.route}>
            <Link className="text-grey6" to={crumb.route}>
              {crumb.title}
            </Link>
            <span className="mx-0.5 text-grey8">/</span>
          </div>
        );
      })}
    </div>
  );
}
