import React from 'react';
import Avatar from 'components/Avatar';

const SingleValue = (props: any) => {
  const { data } = props;
  return (
    <div className="flex items-center text-14">
      {data.hasOwnProperty('image') && (
        <div className="flex-shrink-0 inline-block mr-0.5 w-3 h-3">
          <Avatar src={props.data.image} name={props.data.label} />
        </div>
      )}

      {data.label && data.label.replace && (
        <div className="text-dark">{data.label.replace('NEW__', '')}</div>
      )}
    </div>
  );
};

export default SingleValue;
