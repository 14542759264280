const dateFilterOptions = [
  'all',
  'today',
  'last1',
  'last7',
  'last30',
  'last90',
  'last365',
  'custom',
];

export { dateFilterOptions };
