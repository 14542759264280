
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttachmentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Attachment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"size"},"value":{"kind":"StringValue","value":"medium","block":false}}],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mediaSize"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mediaUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mediaFilename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mediaContentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"aspectRatio"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"length"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"faces"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"width"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"height"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"top"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"left"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":236}};
    doc.loc.source = {"body":"fragment AttachmentFields on Attachment {\n  id\n  thumbnail(size: \"medium\")\n  mediaSize\n  mediaUrl\n  mediaFilename\n  mediaContentType\n  type\n  aspectRatio\n  length\n  createdAt\n  faces {\n    id\n    width\n    height\n    top\n    left\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
