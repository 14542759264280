
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RequestFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Request"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sentAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"downloadedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"suggestion"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttachmentFields"},"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":214}};
    doc.loc.source = {"body":"#import 'graphql/fragments/AttachmentFields.graphql'\n\nfragment RequestFields on Request {\n  id\n  sentAt\n  downloadedAt\n  status\n  suggestion {\n    id\n    url\n    attachments {\n      ...AttachmentFields\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('graphql/fragments/AttachmentFields.graphql').definitions));


      module.exports = doc;
    
