import * as io from 'socket.io-client';
import loadPublishedMediaItem from 'graphql/operations/loadPublishedMediaItem';

const SOCKET_SERVER_URL = (process.env.REACT_APP_SOCKET_SERVER_URL ||
  process.env.REACT_APP_API_SERVER_URL) as string;
const socket = io.connect(SOCKET_SERVER_URL);

// Core events
socket.on('connect', () => {
  socket.emit('coolKidsOnly');
});

socket.on('itemUpdated', async ({ ids }: { ids: number[] }) => {
  await loadPublishedMediaItem(ids);
});

// Temporarily disable ingesting new items
// socket.on('itemCreated', ingestNewItem);

export default socket;
