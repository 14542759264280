import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from 'components/FormattedDateTime';

interface Props {
  dateRange?: string;
}

const tz = 'America/New_York';

const formatDateTime = (dateTime: Date | number) => (
  <FormattedDateTime
    value={dateTime}
    separator={
      <span>
        {' '}
        <FormattedMessage id="Global__At" />{' '}
      </span>
    }
  />
);

const DateDescriptor = ({ dateRange }: Props) => {
  if (dateRange === 'today') {
    const time = moment.tz(moment().startOf('day'), tz);
    return (
      <span>
        <strong>{formatDateTime(time.toDate())} ET </strong>
        <FormattedMessage id="DateFilter__RightNow" />
      </span>
    );
  }

  if (dateRange && dateRange.match(/^last/)) {
    let dayCount = parseInt(dateRange.replace('last', ''), 10);

    // HACK: We want to use last2 as a "fake" Yesterday
    if (dayCount === 2) {
      dayCount = 1;
    }

    const startTime = moment.tz(
      moment()
        .subtract(dayCount, 'days')
        .startOf('day'),
      tz
    );
    const endTime = moment.tz(
      moment()
        .subtract(1, 'days')
        .endOf('day'),
      tz
    );

    return (
      <strong>
        {formatDateTime(startTime.toDate())} -{' '}
        {formatDateTime(endTime.toDate())} ET
      </strong>
    );
  }

  return (
    <strong>
      <FormattedMessage id="DateFilter__AllTime" />
    </strong>
  );
};

export default DateDescriptor;
