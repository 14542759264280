import React from 'react';
import ClickOutside from 'react-onclickout';
import { TippyProps } from '@tippy.js/react';
import cx from 'classnames';
import Tooltip from './Tooltip';
import hasClass from 'helpers/hasClass';
import noPropagate from 'helpers/noPropagate';

interface Props {
  children: React.ReactNode;
  placement?: TippyProps['placement'];
  renderHeading?: React.ReactNode;
  renderButton: (onClick: any) => TippyProps['children'];
  distance?: number;
  onOpen?: () => void;
  onOpened?: () => void;
}

interface State {
  isOpen: boolean;
}

export default class FlyoutMenu extends React.Component<Props, State> {
  state = { isOpen: false };

  toggleOpen = () => this.setState(state => ({ isOpen: !state.isOpen }));

  close = () => this.setState({ isOpen: false });

  clickOut = (e: any) => {
    if (this.state.isOpen && !hasClass(e.target, 'tippy-tooltip')) {
      this.close();
    }
  };

  render() {
    const { children, renderHeading, renderButton, placement } = this.props;
    const { isOpen } = this.state;

    return (
      <ClickOutside onClickOut={this.clickOut}>
        <Tooltip
          placement={placement || 'bottom-start'}
          trigger="manual"
          isVisible={isOpen}
          theme="FlyoutMenu"
          interactive
          distance={this.props.distance || 4}
          duration={[0, 0]}
          delay={[0, 0]}
          onShow={this.props.onOpen || (() => {})}
          onShown={this.props.onOpened || (() => {})}
          content={
            <div>
              {renderHeading}

              {React.Children.map(children, child => {
                const props = (child as any) && (child as any).props;
                const disabled = props && props.disabled;
                return (
                  <div
                    onClick={this.close}
                    className={cx('flyout-menu-item', {
                      'flyout-menu-item-disabled': disabled,
                    })}
                  >
                    {child}
                  </div>
                );
              })}
            </div>
          }
        >
          {renderButton(noPropagate(this.toggleOpen))}
        </Tooltip>
      </ClickOutside>
    );
  }
}
