import React from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';

interface Props extends TippyProps {}

const Tooltip = ({ children, ...tooltipProps }: Props) => {
  return (
    <Tippy
      animateFill={false}
      theme="socialie"
      duration={[150, 150]}
      popperOptions={{
        modifiers: {
          flip: {
            enabled: false,
          },
        },
      }}
      {...tooltipProps}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
