import { useReducer } from 'react';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import without from 'lodash/without';
import intersection from 'lodash/intersection';

type ID = number | string;

interface State {
  selectedIds: ID[];
  lastSelectedId?: ID;
}

interface ToggleSelectedAction {
  type: 'TOGGLE_SELECTED';
  payload: { id: ID };
}

interface SelectOnlyAction {
  type: 'SELECT_ONLY';
  payload: { id: ID };
}

interface SelectMultipleAction {
  type: 'SELECT_MULTIPLE';
  payload: { ids: ID[] };
}

interface SelectRangeAction {
  type: 'SELECT_RANGE';
  payload: { id: ID; allIds: ID[] };
}

type ActionTypes =
  | ToggleSelectedAction
  | SelectOnlyAction
  | SelectMultipleAction
  | SelectRangeAction;

const reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case 'TOGGLE_SELECTED':
      let selectedIds = state.selectedIds;
      if (includes(state.selectedIds, action.payload.id)) {
        selectedIds = without(selectedIds, action.payload.id);
      } else {
        selectedIds = [...selectedIds, action.payload.id];
      }
      return { selectedIds, lastSelectedId: action.payload.id };

    case 'SELECT_ONLY':
      return {
        selectedIds: [action.payload.id],
        lastSelectedId: action.payload.id,
      };

    case 'SELECT_MULTIPLE':
      return { selectedIds: action.payload.ids };

    case 'SELECT_RANGE': {
      const { id, allIds } = action.payload;
      const loadedSelectedIds = intersection(allIds, state.selectedIds);
      const lastSelected = state.lastSelectedId || id;
      const lastSelectedIdx = allIds.indexOf(lastSelected);
      const nextIdx = allIds.indexOf(id);
      const range = allIds.slice(
        Math.min(lastSelectedIdx, nextIdx),
        Math.max(lastSelectedIdx, nextIdx) + 1
      );

      const isAlreadySelected = includes(loadedSelectedIds, id);

      let selectedIds;
      if (isAlreadySelected) {
        selectedIds = without(loadedSelectedIds, ...range);
      } else {
        selectedIds = uniq([...loadedSelectedIds, ...range]);
      }

      return { selectedIds, lastSelectedId: id };
    }

    default:
      return state;
  }
};

export default function useSelectable(allIds: ID[] = []) {
  const [state, dispatch] = useReducer(reducer, {
    selectedIds: [],
    lastSelectedId: undefined,
  });

  const loadedSelectedIds = intersection(allIds, state.selectedIds);

  return {
    toggleSelected: (id: ID) => {
      dispatch({ type: 'TOGGLE_SELECTED', payload: { id } });
    },

    selectOnly: (id: ID) => {
      dispatch({ type: 'SELECT_ONLY', payload: { id } });
    },

    selectMultiple: (ids: ID[]) => {
      dispatch({ type: 'SELECT_MULTIPLE', payload: { ids } });
    },

    selectAll: () => {
      dispatch({ type: 'SELECT_MULTIPLE', payload: { ids: allIds } });
    },

    selectNone: () => {
      dispatch({ type: 'SELECT_MULTIPLE', payload: { ids: [] } });
    },

    selectRange: (id: ID) => {
      dispatch({ type: 'SELECT_RANGE', payload: { id, allIds } });
    },

    isSelected: (id: ID) => includes(state.selectedIds, id),

    selectedIds: loadedSelectedIds,
  };
}
