import { useContext } from 'react';
import Context from './Context';

export default function useFlashMessages() {
  const { messages, createMessage, removeMessage } = useContext(Context);
  return {
    flashMessages: messages,
    addFlashMessage: createMessage,
    removeFlashMessage: removeMessage,
  };
}
