import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache({
  typePolicies: {
    PublishedMediaItem: {
      fields: {
        requests: relayStylePagination(),
      },
    },
  },
});

// TODO: Monkey-patching in a fix for an open issue suggesting that
// `readQuery` should return null or undefined if the query is not yet in the
// cache: https://github.com/apollographql/apollo-feature-requests/issues/1
cache['originalReadQuery'] = cache.readQuery;
cache.readQuery = (...args: any[]) => {
  try {
    return cache['originalReadQuery'](...args);
  } catch (err) {
    return undefined;
  }
};

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_SERVER_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem('satellizer_token') || window.Socialie?.tempUserToken;

  return {
    headers: {
      ...headers,
      'X-Socialie-Authorization': token ? `Bearer ${token}` : '',
    },
  };
});

export default new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  connectToDevTools: true, // process.env.NODE_ENV !== 'production',
});
