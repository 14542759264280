import React from 'react';
import useFlashMessages from 'hooks/useFlashMessages';
import { IconClose } from 'icons';

export default function FlashMessages() {
  const { flashMessages, removeFlashMessage } = useFlashMessages();

  if (!flashMessages.length) return null;

  return (
    <div className="fixed bottom-0 left-0 p-2" style={{ zIndex: 101 }}>
      {flashMessages.map(msg => {
        const bgColor = msg.isError ? 'error' : 'green';
        const removeMsg = () => removeFlashMessage(msg.id);
        return (
          <div
            className={`py-1 px-2 h-7 flex items-center w-48 rounded relative bg-${bgColor}`}
          >
            <span className="text-16 text-white">{msg.text}</span>

            <button
              onClick={removeMsg}
              className="m-1 w-2 h-2 flex items-center justify-center bg-white bg-opacity-50 rounded-full absolute right-0 top-0"
            >
              <IconClose className={`w-1 h-1 stroke-2 text-${bgColor}`} />
            </button>
          </div>
        );
      })}
    </div>
  );
}
