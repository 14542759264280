import { useEffect } from 'react';

export default function useBodyScrollPrevention() {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
}
