import React from 'react';
import socialieLogo from 'images/logo-socialie-sq.png';
import { routes } from 'helpers';
import { useIntl } from 'hooks';
import { EnhancedNavLink } from 'components';
import useCurrentUser from 'hooks/useCurrentUser';
import CoolKidRole from 'types/CoolKidRole';

const logOut = () => {
  window.localStorage.setItem('satellizer_token', '');
  window.location.href = '/';
};

export default function TopBar() {
  const { t } = useIntl();
  const user = useCurrentUser();

  const hasRole = (role: CoolKidRole) =>
    user?.coolKid || user?.coolKidRoles?.includes(role);

  return (
    <div className="fixed left-0 top-0 z-40 w-full h-8 border-b border-border bg-white">
      <div className="flex items-center">
        {/* Logo */}
        <img className="block w-8 h-8 mr-2" src={socialieLogo} alt="Socialie" />

        {/* Main Nav */}
        <div className="flex items-center text-sm">
          {hasRole('magic_tracking') && (
            <NavItem to={routes.magicTracking.posts.index}>
              {t('TopBar__MagicTracking')}
            </NavItem>
          )}

          {hasRole('stats') && (
            <NavItem to={routes.reports.index}>{t('TopBar__Reports')}</NavItem>
          )}

          {hasRole('faces') && (
            <NavItem to={routes.facePrints.index}>
              {t('TopBar__FacePrints')}
            </NavItem>
          )}

          {hasRole('customers') && (
            <NavItem to={routes.customers.index}>
              {t('TopBar__Customers')}
            </NavItem>
          )}

          {hasRole('fan_accounts') && (
            <NavItem to={routes.fanAccounts.index}>
              {t('TopBar__FanAccounts')}
            </NavItem>
          )}

          {hasRole('comments') && (
            <NavItem to={routes.comments.index}>
              {t('TopBar__Comments')}
            </NavItem>
          )}
        </div>

        <div className="ml-auto pr-2">
          <button className="text-sm text-bodyText" onClick={logOut}>
            {t('TopBar__LogOut')}
          </button>
        </div>
      </div>
    </div>
  );
}

interface NavItemProps {
  to: string;
  children: React.ReactNode;
}

function NavItem({ to, children }: NavItemProps) {
  return (
    <EnhancedNavLink
      className="no-underline hover:underline mr-2"
      inactiveClassName="text-dark"
      activeClassName="text-socialiePink"
      to={to}
    >
      {children}
    </EnhancedNavLink>
  );
}
