import React from 'react';
import { IconCheck } from '../icons';

export default function Checkmark() {
  return (
    <div className="w-2 h-2 flex items-center justify-center bg-socialiePink rounded-full">
      <IconCheck className="w-1.75 h-1.75 text-white" />
    </div>
  );
}
