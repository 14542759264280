import React from 'react';
import { IconTriangleDown } from 'icons';

const DropdownIndicator = (props: any) => (
  <div className="pr-1 text-grey1">
    <IconTriangleDown className="w-1.5 h-1.5" />
  </div>
);

export default DropdownIndicator;
