import { useState } from 'react';
import omitBy from 'lodash/omitBy';
import qs from 'qs';
import { useDebouncedCallback } from 'use-debounce';

export type UpdateFilterFn<FilterShape> = (
  updates: Partial<FilterShape>,
  options?: { debounce: boolean }
) => void;

export default function useFilterManager<FilterShape>(
  defaultFilter: FilterShape,
  useQueryString = false
) {
  const initialFilter: FilterShape = useQueryString
    ? Object.assign(
        {},
        defaultFilter,
        qs.parse(window.location.search, { ignoreQueryPrefix: true })
      )
    : defaultFilter;

  const [filter, setFilter] = useState(initialFilter);
  const [debouncedFilter, setDebouncedFilter] = useState(initialFilter);
  const [debouncedSetDebouncedFilter] = useDebouncedCallback(
    (updates: FilterShape) => setDebouncedFilter(updates),
    500
  );

  const updateFilter: UpdateFilterFn<FilterShape> = (updates, options) => {
    const defaultOpts = { debounce: true };
    const opts = { ...defaultOpts, ...options };
    const nextFilter = Object.assign({}, filter, updates);
    setFilter(nextFilter);
    opts.debounce
      ? debouncedSetDebouncedFilter(nextFilter)
      : setDebouncedFilter(nextFilter);

    if (useQueryString && window.history && window.history.replaceState) {
      const queryString = qs.stringify(
        omitBy(nextFilter as any, (val, key) => {
          return !val || val.length === 0 || defaultFilter[key] === val;
        }),
        { arrayFormat: 'brackets' }
      );

      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + '?' + queryString
      );
    }
  };

  return { updateFilter, filter, debouncedFilter };
}
