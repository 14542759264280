import React from 'react';
import { ModalAdapter } from './components';
import { useIntl } from 'hooks';
import { IconClose } from 'icons';

interface ModalProps {
  isOpen: boolean;
  heading: string;
  onRequestClose: () => void;
  children: React.ReactNode;
  theme: {
    small?: boolean;
    medium?: boolean;
    large?: boolean;
  };
}

export default function Modal({
  heading,
  onRequestClose,
  isOpen,
  children,
  theme,
}: ModalProps) {
  const { t } = useIntl();
  const themeWidth = {
    small: '400px',
    medium: '500px',
    large: '650px',
  };
  return (
    <>
      <ModalAdapter
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(0,0,0,0.75)',
          },
          content: {
            width: theme.small
              ? themeWidth.small
              : theme.medium
              ? themeWidth.medium
              : theme.large
              ? themeWidth.large
              : themeWidth.medium,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
          },
        }}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <ModalHeading>
          <div className="font-bold">{t(heading)}</div>{' '}
          <button onClick={onRequestClose} type="button">
            <IconClose className="w-2 h-2" />
          </button>
        </ModalHeading>
        <div className="p-2">{children}</div>
      </ModalAdapter>
    </>
  );
}

interface ModalHeadingProps {
  children: React.ReactNode;
}

function ModalHeading({ children }: ModalHeadingProps) {
  return (
    <div className="flex items-center justify-between border-b border-solid border-grey-4 text-dark p-2 text-lg">
      {children}
    </div>
  );
}
