import React from 'react';

interface CardProps {
  children: React.ReactNode;
  bgClassName?: string;
}

export default function Card(props: CardProps) {
  const { children, bgClassName = 'bg-white' } = props;

  return (
    <div className={`py-3 px-2.5 ${bgClassName} rounded mb-3`}>{children}</div>
  );
}
