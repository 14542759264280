import React from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import FormattedDateTime from 'components/FormattedDateTime';

interface Props {
  startDate: string;
  endDate: string;
  onChangeStartDate: (startDate: string) => void;
  onChangeEndDate: (endDate: string) => void;
}

const formatDateTime = (dateTime: Date | number) => (
  <FormattedDateTime
    value={dateTime}
    separator={
      <span>
        {' '}
        <FormattedMessage id="Global__At" />{' '}
      </span>
    }
  />
);

class CustomInput extends React.Component<any> {
  private buttonRef: any;

  componentDidMount() {
    if (this.buttonRef && !this.props.end) {
      this.buttonRef.focus();
      this.buttonRef.click();
    }
  }

  render() {
    let date = moment(this.props.value).startOf('day');
    if (this.props.end) {
      date = moment(this.props.value).endOf('day');
    }
    const value = formatDateTime(date.toDate());

    return (
      <button
        type="button"
        className="text-socialieBlue focus:text-socialieBlue"
        ref={(el: any) => (this.buttonRef = el)}
        onClick={this.props.onClick}
      >
        {value}
      </button>
    );
  }
}

export default class CustomPicker extends React.Component<Props> {
  private endPickerRef: any;

  changeDate = (value: moment.Moment | null, which: 'start' | 'end') => {
    const { onChangeStartDate, onChangeEndDate } = this.props;
    if (!value) return;
    const asString = value.format('YYYY-MM-DD');
    const onChange = which === 'start' ? onChangeStartDate : onChangeEndDate;
    onChange(asString);

    if (which !== 'end' && this.endPickerRef) {
      this.endPickerRef.input.buttonRef.focus();
      this.endPickerRef.input.buttonRef.click();
    }
  };

  render() {
    const { startDate, endDate } = this.props;
    return (
      <div className="inline-block font-bold">
        <DatePicker
          customInput={<CustomInput />}
          onChange={value => this.changeDate(value, 'start')}
          selected={moment(startDate)}
          maxDate={moment(endDate)}
        />
        {' - '}
        <DatePicker
          ref={(el: any) => (this.endPickerRef = el)}
          customInput={<CustomInput end />}
          onChange={value => this.changeDate(value, 'end')}
          selected={moment(endDate)}
          minDate={moment(startDate)}
        />
      </div>
    );
  }
}
