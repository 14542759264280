import React from 'react';
import without from 'lodash/without';
import { dateFilterOptions } from 'config';
import FilterSelector from '../FilterSelector';
import { CustomPicker, DateDescriptor } from './components';
import { FormattedMessage } from 'react-intl';

interface Props {
  dateRange?: string;
  startDate: string;
  endDate: string;
  onChange: (updates: any) => void;
  excludeOptions?: string[];
  options?: string[];
}

export default class DateFilter extends React.Component<Props> {
  render() {
    const { dateRange, onChange, excludeOptions } = this.props;
    const options =
      this.props.options ||
      without(dateFilterOptions, ...(excludeOptions || []));
    return (
      <div className="flex items-center">
        <FilterSelector
          name="DateFilter"
          value={dateRange}
          onChange={(val: string) => onChange({ dateRange: val })}
          options={options}
        />
        <div className="ml-0.5 text-light text-xs">
          <FormattedMessage id="DateFilter__ResultsFrom" />{' '}
          {dateRange === 'custom' ? (
            <CustomPicker
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              onChangeStartDate={(val: string) => onChange({ startDate: val })}
              onChangeEndDate={(val: string) => onChange({ endDate: val })}
            />
          ) : (
            <DateDescriptor dateRange={dateRange} />
          )}
        </div>
      </div>
    );
  }
}
