import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  condition: any;
}

export default function LinkIf({ to, condition, children, ...props }: Props) {
  if (condition) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  return <span {...props}>{children}</span>;
}
