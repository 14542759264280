import React, { useState, useRef, useEffect, useCallback } from 'react';
import Context from './Context';
import uuid from 'uuid4';
import {
  FlashMessage,
  CreateFlashMessageFn,
  CreateFlashMessageOptions,
} from './types';

interface Props {
  children: React.ReactNode;
}

const defaultCreateOptions: CreateFlashMessageOptions = {
  isError: false,
  timeout: 2500,
};

export default function FlashMessageProvider({ children }: Props) {
  const [messages, setMessages] = useState<FlashMessage[]>([]);
  const idsPendingRemoval = useRef<string[]>([]);
  const timeouts = useRef<number[]>([]);

  const removeMessage = useCallback(
    (id: string) => {
      const nextMessages = messages.filter(msg => msg.id !== id);
      idsPendingRemoval.current = idsPendingRemoval.current.filter(
        x => x !== id
      );
      setMessages(nextMessages);
    },
    [messages]
  );

  useEffect(() => {
    // Find any messages that need to be queued for deletion and queue them up
    const unqueuedMessages = messages.filter(
      msg => !!msg.timeout && idsPendingRemoval.current.indexOf(msg.id) === -1
    );

    unqueuedMessages.forEach(msg => {
      timeouts.current.push(
        window.setTimeout(() => removeMessage(msg.id), msg.timeout as number)
      );
      idsPendingRemoval.current.push(msg.id);
    });

    // Clear any timeouts on unmount
    const currentTimeout = timeouts.current;
    return () => {
      currentTimeout.forEach(timeout => clearTimeout(timeout));
    };
  }, [messages, removeMessage]);

  const createMessage: CreateFlashMessageFn = (text, options) => {
    const opts = { ...defaultCreateOptions, ...options };
    const id = uuid();
    const message = { text, id, ...opts };
    setMessages([...messages, message]);
  };

  return (
    <Context.Provider value={{ messages, createMessage, removeMessage }}>
      {children}
    </Context.Provider>
  );
}
